var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "speech-bubble ml-0",
      attrs: { color: "secondary", "max-width": "125px" }
    },
    [
      _c("v-card-text", { staticClass: "pa-1" }, [
        _c(
          "div",
          { staticClass: "text-caption white--text text-wrap text-center" },
          [_vm._v(" " + _vm._s(_vm.message) + " ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }