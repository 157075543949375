var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 py-6 vitrueGrey--text", attrs: { fluid: "" } },
    [
      _vm.page === 0
        ? _c(
            "div",
            [
              _c("file-drop", {
                staticClass: "mb-12",
                staticStyle: { height: "250px" },
                attrs: {
                  maxFileSizeMb: 2,
                  extraInformationText: _vm.addingUsers
                    ? _vm.$t("account.team.file.infoText")
                    : _vm.$t("invite.viaFile.infoText")
                },
                on: {
                  "file-error-type": function($event) {
                    _vm.fileErrorMessage = $event
                  }
                },
                model: {
                  value: _vm.uploadedFile,
                  callback: function($$v) {
                    _vm.uploadedFile = $$v
                  },
                  expression: "uploadedFile"
                }
              }),
              this.uploadedFile ||
              _vm.fileLoading ||
              _vm.fileLoaded ||
              _vm.fileError
                ? _c(
                    "div",
                    {
                      staticClass: "mb-4 text-h6",
                      staticStyle: { "min-height": "75px" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "my-0 py-0 mx-1",
                          attrs: { justify: "space-between" }
                        },
                        [
                          this.uploadedFile
                            ? _c("p", { staticClass: "pr-8 my-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("invite.viaFile.fileName") +
                                        this.uploadedFile.name
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c("p", { staticClass: "my-0" }, [
                            _vm._v(_vm._s(_vm.loadingBarText))
                          ])
                        ]
                      ),
                      _vm.fileLoading || _vm.fileLoaded || _vm.fileError
                        ? _c("v-progress-linear", {
                            attrs: {
                              color: _vm.loadingBarColour,
                              indeterminate: !_vm.fileLoaded && !_vm.fileError,
                              value:
                                _vm.fileLoaded || _vm.fileError ? "100" : "",
                              height: "20"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.page === 1
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "mx-0 mb-4",
                  attrs: { align: "center", justify: "start" }
                },
                [
                  _c("p", { staticClass: "ma-0 mr-3 text-body-1" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("invite.viaFile.fileName")) + " "
                    ),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(this.uploadedFile.name))
                    ])
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-6 primary--text text-h6",
                      attrs: {
                        tile: "",
                        depressed: "",
                        small: "",
                        color: "#DFEDFF"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("go-to-previous-page")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.reload")))]
                  )
                ],
                1
              ),
              _c("v-checkbox", {
                staticClass: "text-body-1",
                attrs: { label: _vm.$t("invite.viaFile.headerRowLabel") },
                model: {
                  value: _vm.includeHeaders,
                  callback: function($$v) {
                    _vm.includeHeaders = $$v
                  },
                  expression: "includeHeaders"
                }
              }),
              _c("p", { staticClass: "text-body-1" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("invite.viaFile.instructions.matchHeaders")) +
                    " "
                )
              ]),
              _c(
                "v-row",
                {
                  staticClass: "ma-0 flex-nowrap",
                  staticStyle: { "overflow-x": "auto" },
                  attrs: { justify: "start" }
                },
                _vm._l(_vm.entriesToDisplay[0], function(column, columnIndex) {
                  return _c(
                    "div",
                    {
                      key: columnIndex,
                      staticClass: "my-2 mr-1",
                      attrs: { item: column, index: columnIndex }
                    },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "columnBorder vitrueGrey--text",
                          class: _vm.isColumnUsed(columnIndex)
                            ? "selectedBorder selectedColor"
                            : "columnBorder",
                          attrs: { elevation: "0", outlined: "", width: "225" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "my-0 py-3",
                            attrs: {
                              id: "dropDown" + columnIndex,
                              items: _vm.availableTags(
                                _vm.usedHeaders[columnIndex]
                              ),
                              "hide-details": "",
                              "hide-selected": "",
                              "no-data-text": _vm.$t(
                                "invite.viaFile.instructions.noHeadersRemaining"
                              )
                            },
                            on: { change: _vm.dropDownChanged },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "pl-4 text-body-1 textOverflow",
                                          class:
                                            item === _vm.defaultColumnHeader
                                              ? "disabled--text"
                                              : "vitrueGrey--text",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.translateHeaderText(item)
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.translateHeaderText(item)
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.usedHeaders[columnIndex],
                              callback: function($$v) {
                                _vm.$set(_vm.usedHeaders, columnIndex, $$v)
                              },
                              expression: "usedHeaders[columnIndex]"
                            }
                          }),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-4 text-caption",
                              attrs: { dense: "" }
                            },
                            _vm._l(_vm.entriesToDisplay, function(
                              item,
                              rowIndex
                            ) {
                              return _c(
                                "v-col",
                                {
                                  key: rowIndex,
                                  staticClass: "my-0 textOverflow",
                                  staticStyle: { "min-height": "30px" },
                                  attrs: { cols: "12" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.entriesToDisplay[rowIndex][
                                          columnIndex
                                        ]
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.page === 2
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "mx-0 mb-4",
                  attrs: { align: "center", justify: "start" }
                },
                [
                  _c("p", { staticClass: "ma-0 mr-3 text-body-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.addingUsers
                            ? _vm.$tc(
                                "invite.viaFile.instructions.teamMemberPreviewInfo",
                                _vm.invitationList.length,
                                { n: _vm.invitationList.length }
                              )
                            : _vm.$tc(
                                "invite.viaFile.instructions.previewInfo",
                                _vm.invitationList.length,
                                { n: _vm.invitationList.length }
                              )
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary--text text-h6",
                      attrs: {
                        tile: "",
                        depressed: "",
                        small: "",
                        color: "#DFEDFF"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("go-to-previous-page")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.change")))]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-0 py-2 flex-nowrap",
                  staticStyle: { "overflow-x": "auto" }
                },
                _vm._l(Object.keys(_vm.reducedInviteList[0]), function(
                  key,
                  columnIndex
                ) {
                  return _c(
                    "div",
                    {
                      key: columnIndex,
                      staticClass: "mr-1",
                      attrs: { item: key, index: columnIndex }
                    },
                    [
                      !_vm.invitationList.every(function(i) {
                        return i[columnIndex] === ""
                      })
                        ? _c(
                            "v-sheet",
                            {
                              staticClass:
                                "selectedBorder selectedColor vitrueGrey--text",
                              attrs: {
                                elevation: "0",
                                outlined: "",
                                width: "200"
                              }
                            },
                            [
                              _c("v-select", {
                                staticClass: "my-0 py-3",
                                attrs: {
                                  value: _vm.inviteListHeaders[columnIndex],
                                  items: _vm.inviteListHeaders,
                                  "hide-details": "",
                                  "hide-selected": "",
                                  disabled: "",
                                  "append-icon": "false"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pl-4 text-body-1 textOverflow",
                                              staticStyle: { width: "100%" }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.translateHeaderText(
                                                      item
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "v-row",
                                {
                                  staticClass: "px-4 text-caption",
                                  attrs: { dense: "" }
                                },
                                _vm._l(_vm.reducedInviteList, function(
                                  item,
                                  rowIndex
                                ) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: rowIndex,
                                      staticClass: "my-0 textOverflow",
                                      staticStyle: { "min-height": "30px" },
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.reducedInviteList[rowIndex][key]
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }